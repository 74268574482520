/* ====================================
// css variables
// ==================================== */

@import "bootstrap/scss/bootstrap-grid";
:root {
  font-family: Inter, sans-serif;
  font-feature-settings: "liga" 1, "calt" 1; /* fix for Chrome */
}
@supports (font-variation-settings: normal) {
  :root {
    font-family: InterVariable, sans-serif;
  }
}

$color-black: #131313;
$color-white: #fff;
$color-enhanced: #eb7700;

/* ====================================
// reset rules
// ==================================== */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  scroll-behavior: smooth;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  font-size: 1rem;
  line-height: 1.25;
}

a {
  color: inherit;
  text-decoration: none;
}

img,
video {
  max-inline-size: 100%;
  block-size: auto;
  vertical-align: middle;
}

.row + .row {
  margin-top: 16px;
}

.row > .col,
.row > [class^="col-"] {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: rgba(90, 90, 90, 0.15);
  border: 1px solid rgba(90, 90, 90, 0.2);
}

/* ====================================
// base rules
// ==================================== */

body {
  font-family: "Inter";
  color: $color-white;
  background-color: $color-black;
}

header {
  h1 {
    font-size: 2rem;
    font-weight: 500;
    em {
      font-style: italic;
      color: $color-enhanced;
    }
  }
  img {
    width: auto;
    height: 35px;
  }
  .col-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-right-radius: 10px;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: larger;
      transition: all 0.3s ease;
      &:hover {
        color: #eb7700;
        text-shadow: 0 0 1px #eb7700;
      }
    }
  }
}

main {
  display: flex;
  padding: 1rem;
  gap: 1rem;
}

.topper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 30px;
  right: 30px;
  background-color: rgba(90, 90, 90, 0.15);
  border: 1px solid rgba(90, 90, 90, 0.2);
  border-radius: 5px;
  padding: 10px;
  &:hover {
    background-color: rgba(90, 90, 90, 0.2);
    color: $color-enhanced;
  }
}

.row {
  height: 100%;
}

.col-3 {
  height: 100%;
  border-radius: 10px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  overflow-y: scroll;
  position: sticky;
  top: 0;
}

.col-12,
.col-md-9 {
  border-radius: 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.col {
  border-bottom-left-radius: 10px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.table-topics {
  ul {
    padding-left: 40px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 3px;
    list-style-type: none;

    li.prime {
      font-weight: 500;
      font-size: 24px;
      color: $color-enhanced;
      margin-top: 10px;
      &:first-of-type {
        margin-top: 0;
      }
    }
    li.second {
      font-weight: 400;
      font-size: 20px;
    }
    li.third {
      font-weight: 400;
      font-size: 16px;
      color: #fff8;
      &:last-of-type {
        margin-bottom: 10px;
      }
    }

    li {
      transition: all 0.3s ease;
      transform-origin: 0 center;
      position: relative;
      cursor: pointer;
      // &::before {
      //   content: "";
      //   z-index: -1;
      //   position: absolute;
      //   top: -2rem;
      //   left: -1rem;
      //   width: 4rem;
      //   height: 8rem;
      //   background: radial-gradient(
      //     50% 50% at 0% 50%,
      //     $color-enhanced 0%,
      //     rgba(255, 166, 0, 0) 100%
      //   );
      //   opacity: 0;
      //   transform: translateX(-3rem);
      //   transition: all 0.5s ease;
      // }
      &.previous-1 {
        transform: translateX(1rem);
      }
      &.previous-2 {
        transform: translateX(0.5rem);
      }
    }

    li:hover {
      color: $color-enhanced;
      transform: translateX(2rem);
      text-shadow: 0 0 1px $color-enhanced;
      // &::before {
      //   opacity: 1;

      //   transform: translateX(-1rem);
      // }
      & ~ li:first-of-type {
        transform: translateX(1rem);
        & ~ li:first-of-type {
          transform: translateX(0.5rem);
        }
      }
      & + li {
        transform: translateX(1rem);
        & + li {
          transform: translateX(0.5rem);
        }
      }
    }
    li:active {
      color: $color-enhanced;
      text-shadow: 0 0 1px $color-enhanced;
    }
  }
}

.framer {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  section {
    border-bottom: solid 1px rgba(255, 255, 255, 0.1);
    padding-bottom: 1.5rem;
  }
  img {
    margin: 1rem 0;
    width: 100%;
    height: auto;
    border-radius: 10px;
    filter: drop-shadow(0 5px 10px rgba(19, 19, 19, 1));
  }
  em {
    font-style: italic;
    color: $color-enhanced;
    cursor: default;
  }

  ul {
    list-style-type: "->";
    list-style-position: outside;
    padding-left: 2rem;
    li {
      padding-left: 1rem;
      margin-top: 5px;
      font-size: 16px;
      color: #fff8;
      &:first-of-type {
        margin-top: 0;
      }
      a {
        text-decoration: none !important;
        display: inline !important;
        padding: 0;
        background-color: transparent;
        border: none;
        border-radius: 5px;
        &::after {
          content: "↗";
          margin-left: 5px;
          text-decoration: none;
        }
      }
    }
  }

  .popup {
    position: absolute;
    padding: 10px;
    background-color: $color-black;
    border: 1px solid rgba(90, 90, 90, 0.2);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    z-index: 1000;
    opacity: 0;
    transform: translateY(-10px);
    font-style: normal;
    color: $color-white;
    max-width: 300px;
    transition: opacity 0.3s ease, transform 0.3s ease;

    &.show {
      display: block;
      opacity: 1;
      transform: translateY(0);
    }
    img {
      width: 100%;
      height: auto;
      border-radius: 5px;
      margin-top: -1px;
    }
  }

  a {
    text-decoration: underline;
    color: $color-enhanced;
    display: inline-block;
    margin: 10px 0;
    padding: 8px;
    background-color: rgba(90, 90, 90, 0.15);
    border: 1px solid rgba(90, 90, 90, 0.2);
    border-radius: 5px;
    &::after {
      content: "↗";
      margin-left: 5px;
      text-decoration: none;
    }
  }
  .key {
    padding: 2px 8px;
    display: inline-block;
    background-color: rgba(90, 90, 90, 0.15);
    border: 1px solid rgba(90, 90, 90, 0.2);
    border-radius: 5px;
  }
}

@media screen and (max-width: 768px) {
  .col-3 {
    display: none;
  }
}
